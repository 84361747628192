/* banner start */
.banner {
  position: relative;
  background: center/cover no-repeat, cl(primary, 0.5);
  color: cl(white);
 // margin-top: rem(75px);
  &:before{
    content: "";
    position: absolute;
    z-index: 1;
    background-color: cl(black,0.17);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &--home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(md) {
      height: rem(500px);
    }
    @include media-breakpoint-down(sm) {
      height: rem(400px);
    }

  }
  .mouse-scroll{
    position: absolute;
    @extend .pos-centerX;
    bottom: 1rem;
    z-index: 20;
    display: block;
    height: 2rem;
    cursor: pointer;
    img{
      width: 2rem;
    }
  }
  &--page {
    height: rem(350px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: rem(100px 0 20px 0);
    background:left /cover no-repeat, cl(primary);
    color: cl(white);
    @include media-breakpoint-down(sm) {
      height: rem(250px);
      padding: rem(50px 0 50px 0);
    }
  }
  &-video {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    &.banner-video-absolute{
      position: absolute;
    }
    &:after{
      content: "";
      @extend .full;
      width: 100%;
      height: 100%;
      background-color: cl(black, 0.17);
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 16;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .page-ttl{
      font-size: rem(50px);
      line-height: 1.167;
      font-family: $font;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      text-align: center;
      max-width: 70vw;
      font-weight: 700;
      @include media-breakpoint-down(md) {
        font-size: rem(40px);
      }
      @include media-breakpoint-down(sm) {
        font-size: rem(36px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(30px);
      }
    }
    .btn{
      min-width: rem(180px);
    }
    p {
      margin: 0;
    }
  }

}
/* banner end */

